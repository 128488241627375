import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="Regulamin" />

      <div className="regulaminContent proza">
        <h1>Regulamin</h1>
        <h2>I. INFORMACJE OGÓLNE</h2>
        <p>
          Regulamin określa zasady sprzedaży produktów będących ofertą sklepu
          Zuti Grafika należącego do Autorskiej Pracowni Architektury Grażyna
          Zuterek, z&nbsp;siedzibą w Łodzi przy ul. Gimnastycznej 92, NIP
          7281124328, REGON 470034970.
        </p>
        <p>
          Autorska Pracownia Architektury Grażyna Zuterek wymieniana będzie jako
          „Sprzedawca” natomiast Klient prywatny lub firma składająca zamówienie
          nazwana będzie „ Zamawiającym” lub „Klientem” w poniższym Regulaminie.
        </p>
        <h2>II. ZAKRES STOSOWANIA REGULAMINU I JEGO ZMIANY</h2>
        <p>
          Regulamin określa zasady prowadzenia sprzedaży produktów, składania
          zamówień oraz zawierania umów sprzedaży.
        </p>
        <p>
          W kwestiach nieuregulowanych Regulaminem mają zastosowanie przepisy
          kodeksu cywilnego oraz ustaw szczególnych.
        </p>
        <p>
          Aktualna treść Regulaminu jest dostępna dla Klienta w zakładce
          „Regulamin” widniejącej na stronie internetowej sklepu.
        </p>
        <p>
          Sprzedawca zastrzega sobie prawo do dokonywania zmian Regulaminu w
          dowolnym czasie, poprzez publikację na stronie nowej wersji
          Regulaminu.
        </p>
        <p>
          Zamawiający posiada możliwość utrwalenia postanowień Regulaminu
          poprzez skopiowanie treści Regulaminu lub wydrukowanie treści
          Regulaminu w&nbsp;każdym czasie.
        </p>
        <h2>III. INFORMACJE O PRODUKTACH</h2>
        <p>
          Produkty oferowane w sklepie są oznaczone, ze wskazaniem nazwy własnej
          produktu oraz jego ceny. Informacje są uzupełnione o wskazanie
          materiału z jakiego wykonano produkt, wymiarów i kolorystyki,
          informację o czasie oczekiwania na produkt, jak również wszelkie inne
          informacje jakie uznane przez Sprzedającego za wartościowe dla
          Zamawiającego.
        </p>
        <p>
          Zdjęcia i prezentacje oferowanych produktów mają charakter wyłącznie
          prezentacyjny, zaś ich ewentualne odstępstwo od rzeczywistego wyglądu
          produktu nie może stanowić podstawy do zgłaszania jakichkolwiek
          roszczeń.
        </p>
        <p>
          Wszystkie podane ceny produktów znajdujących się na stronie są cenami
          brutto podanymi w polskich złotych i zawierają podatek VAT w wysokości
          23%.
        </p>
        <p>
          Ceny produktów nie zawierają kosztów wysyłki. Dostępny asortyment
          produktów, ich specyfikacja oraz ceny mogą ulegać bieżącym zmianom,
          np. poszczególne produkty mogą być wprowadzane bądź wycofywane z
          obrotu oraz obejmowane lub wycofywane z promocji, z zastrzeżeniem, iż
          zmiany wprowadzone po zawarciu umowy sprzedaży nie mogą dotyczyć
          Klientów, którzy umowy takie zawarli.
        </p>
        <p>
          Zamieszczone na stronie informacje o produktach nie stanowią oferty w
          rozumienie przepisu art. 66 i 661 kodeksu cywilnego, lecz są
          zaproszeniem do złożenia oferty zawarcia umowy sprzedaży w rozumieniu
          przepisu art. 71 kodeksu cywilnego.
        </p>
        <h2>
          IV. WARUNKI DOKONYWANIA ZAKUPÓW, OCHRONA DANYCH OSOBOWYCH ORAZ
          INFORMACJA O UMOWIE
        </h2>
        <p>
          Warunkiem dokonania zakupu jest zapoznanie się i akceptacja
          niniejszego Regulaminu, każdorazowo przy transakcji, dokonywana
          poprzez zaznaczenie odpowiedniego pola na formularzu udostępnianym na
          stronie sklepu.
        </p>
        <p>
          Zawarcie umowy sprzedaży poprzedzone jest złożeniem zamówienia na
          formularzu dostępnym na stronie oraz oświadczenia, że podane przez
          Zamawiającego dane są prawdziwe.
        </p>
        <p>
          Zamawiający wypełniając formularz zobowiązany jest podać precyzyjne
          informacje odpowiadające aktualnemu stanowi rzeczy, w zakresie
          określonym przez treść formularza. Sprzedający ma prawo do odstąpienia
          od realizacji zamówienia, w przypadku gdy podane przez Zamawiającego
          dane są niepełne lub błędne a uzyskanie prawidłowych informacji jest
          niemożliwe z winy Zamawiającego.
        </p>
        <p>
          Przyjęcie zamówienia do realizacji ma miejsce w momencie otrzymania
          przez Zamawiającego wiadomości e-mail z potwierdzeniem złożonego
          zamówienia. Jest to moment zawarcia umowy kupna sprzedaży pomiędzy
          Zamawiającym oraz Sprzedającym.
        </p>
        <p>
          Administratorem danych osobowych jest Autorska Pracownia Architektury
          Grażyna Zuterek, zarejestrowana w Centralnej Ewidencji i Informacji o
          Działalności Gospodarczej w Łodzi o numerze NIP oraz REGON470034970.
          Administrator zobowiązany jest do zapewnienia wszelkich możliwych i
          prawnie dopuszczalnych środków mających na celu ochronę danych
          osobowych Klientów przed dostępem osób nieupoważnionych. Administrator
          zobowiązuje się do wykorzystywania danych osobowych Klientów
          wyłączenie do celów związanych z realizacją zamówienia. Administrator
          może wykorzystywać zgromadzone dane do celów marketingowych wyłącznie
          po uzyskaniu odrębnej zgody Klienta.
        </p>
        <p>
          Umowy zawierane na skutek akceptacji zamówienia mają charakter umów
          zawieranych na odległość przy pomocy środków porozumiewania się i mają
          do nich zastosowanie przepisy ustawy z dnia 2 marca 2000r. o ochronie
          niektórych praw konsumenckich oraz o odpowiedzialności za szkodę
          wyrządzoną przez produkt niebezpieczny, jak również innych
          odpowiednich aktów prawnych.
        </p>
        <h2>V. DOKONYWANIE ZAKUPÓW</h2>
        <p>
          Warunkiem realizacji zamówienia jest zapłata z góry przez Klienta
          pełnej należności wynikającej z otrzymanego potwierdzenia zamówienia,
          w tym kosztów przesyłki.
        </p>
        <p>Zamawiający może dokonać płatności za pomocą:</p>
        <p>
          Przelewu tradycyjnego bezpośrednio na rachunek bankowy Sprzedającego
          do rozliczeń w PLN. Nr rachunku – 64 1240 3028 1111 0000 2823 7046.
        </p>
        <p>
          Do każdego towaru przyporządkowany jest indywidualny czas realizacji
          zamówienia. Czas realizacji zamówienia określony jest w dniach
          roboczych i jest liczony od dnia otrzymania przez Zamawiającego pełnej
          kwoty należności do dnia wysłania zamówionych towarów. W sytuacji, gdy
          Zamawiający dokona zmówienia towarów o różnym czasie realizacji,
          Sprzedającego wiąże czas najdłuższy.
        </p>
        <p>
          Termin otrzymania towaru jest sumą czasu realizacji i czasu dostawy.
          Terminy dostawy towaru uzależnione są od wybranego przez Zamawiającego
          sposobu dostawy:
        </p>
        <ul>
          <li>◦ Kurier na terytorium Polski – 1-2 dni robocze;</li>
          <li>◦ Odbiór osobisty – po wcześniejszym ustaleniu terminu.</li>
        </ul>
        <p>
          Odpowiedzialność za opóźnienia wynikające z nieterminowego doręczania
          przesyłek ponosi wyłącznie firma kurierska.
        </p>
        <p>
          Zamawiający zostanie poinformowany o nadaniu przesyłki i otrzyma jej
          numer za pośrednictwem poczty elektronicznej w momencie przyjęcia
          zamówienia do realizacji.
        </p>
        <p>
          Zamówienie może zostać złożone bez potrzeby dokonywania rejestracji.
          Zamówienia składane są na formularzu opracowanym przez sklep, zaś ich
          składanie odbywa się poprzez dodanie danego produktu ze wskazaniem
          jego ilości oraz ewentualnie koloru do koszyka z zakupami.
        </p>
        <p>
          Po uzupełnieniu danych niezbędnych do wysyłki produktu Klient może
          zatwierdzić zamówienie poprzez naciśnięcie przycisku „Kupuję i płacę”
          w 2 karcie formularza bądź dokonać zmiany zamawianych produktów jak i
          swoich danych. Po wciśnięciu przycisku „Kupuję i płacę” jakakolwiek
          zmiana treści zamówienie bądź podanych danych przez stronę internetową
          nie jest możliwa.
        </p>
        <p>
          Zamówienie złożone w sposób opisany powyżej stanowi ofertę
          Sprzedającego złożoną Klientowi w zakresie zakupu produktów
          wyróżnionych w zamówieniu za cenę w nim wskazaną z uwzględnieniem
          kosztów dostawy.
        </p>
        <p>
          Po złożeniu zamówienia zostanie wygenerowany komunikat o przyjęciu
          zamówienia – pocztą elektroniczną na adres Klienta.
        </p>
        <p>
          Sprzedający zastrzega sobie prawo odrzucenia zamówienia przy
          jednoczesnym podaniu przyczyny odmowy realizacji w przypadku
          niedotrzymania punktów regulaminu lub z przyczyn określonych w
          uzasadnieniu.
        </p>
        <p>
          Potwierdzenie zamówienia winno zawierać listę produktów objętych
          zamówieniem, ich cenę, warunki płatności, sposób i adres dostawy.
          Przysłanie potwierdzenia otrzymania płatności jest równoznaczne z
          zawarciem umowy sprzedaży.
        </p>
        <h2>VI. REKLAMACJE I ZWROTY</h2>
        <p>
          Zamawiający ma obowiązek sprawdzić stan przesyłki i jej zawartość w
          obecności Dostawcy, w tym przypadku kuriera.
        </p>
        <p>
          W przypadku stwierdzenia powstania szkody transportowej Zamawiający
          winien wykonać akty staranności wymagane przepisami ustawy z dnia
          15.11.1984 r. – Prawo przewozowe (Tekst jednolity: Dz.U.2012.1173) dla
          zachowania odpowiedzialności Dostawcy, a w szczególności spowodować
          protokolarne ustalenie przez Dostawcę stanu przesyłki oraz
          okoliczności powstania szkody.
        </p>
        <p>Wszystkie produkty będące w ofercie sklepu są fabrycznie nowe.</p>
        <p>
          Kupujący ma prawo do reklamacji produktu w ciągu 14 dni od daty
          otrzymania w przypadku jego niezgodności z ofertą lub zamówieniem.
          Reklamacja nie dotyczy uszkodzeń eksploatacyjnych związanych z
          nieprawidłową oprawą produktu oraz jego codziennym użytkowaniem,
          takich jak zadrapania, zarysowania lub odgniecenia wynikające z
          nieprawidłowego użytkowania.
        </p>
        <p>
          Sprzedający zobowiązuje się do pozytywnego rozpatrzenia reklamacji w
          przypadku, gdy dany produkt był wykorzystywany zgodnie z zaleceniami
          producenta i posiadał wadę techniczną. Sprzedający ponosi względem
          Klienta będącego konsumentem odpowiedzialność za niezgodność
          sprzedanych produktów z umową sprzedaży, na zasadach opisanych w
          ustawie o szczególnych warunkach sprzedaży konsumenckiej i zmianie
          Kodeksu cywilnego z dnia 27 lipca 2002 r. oraz w pozostałych,
          odrębnych przepisach.
        </p>
        <p>
          Reklamacje należy składać pisemnie lub pocztą, na adres podany w
          potwierdzeniu zamówienia wysłanego drogą elektroniczną. Aby reklamacja
          była uznana za skuteczną, musi ona zawierać dane Klienta, jego adres
          do korespondencji, przyczynę reklamacji, wskazanie wady, datę jej
          wykrycia, dowód zakupu oraz produkt objęty reklamacją po uprzednim
          powiadomieniu Sprzedającego. Koszty wysyłki towaru pozostają po
          stronie Klienta.
        </p>
        <p>
          Sprzedający rozpatrzy reklamację i poinformuje Klienta o tym fakcie w
          terminie 14 dni od daty otrzymania reklamacji, o ile będzie ona
          spełniać warunki wskazane powyżej.
        </p>
        <p>
          Sprzedający może odmówić przyjęcia reklamacji, jeśli towar zostanie
          odesłany bez uprzedniego powiadomienia i bez spełnienia warunków
          opisanych powyżej. Jeżeli reklamacja nie będzie kompletna, Sprzedający
          wezwie Klienta do uzupełnienia reklamacji, zaś termin 14 dni liczony
          będzie od dnia uzupełnienia reklamacji.
        </p>
        <p>
          W przypadku uwzględnienia reklamacji Sprzedający odeśle na swój koszt
          Klientowi pełnowartościowy produkt (nowy lub naprawiony w sposób
          zapewniający brak wad), lub też zwróci Klientowi cenę produktu
          objętego reklamacją.
        </p>
        <p>
          Klient może odstąpić od zawartej umowy sprzedaży w ciągu 14 dni od
          daty otrzymania towaru, bez podania przyczyny i zwrócić towar
          niezwłocznie do sklepu. Aby zrealizować uprawnienie do odstąpienia od
          umowy sprzedaży należy złożyć oświadczenie drogą elektroniczną lub
          pisemnie o odstąpieniu od zawartej umowy. Po jego złożeniu na adres
          sklepu należy zwrócić produkty, których umowa dotyczy (w stanie bez
          jakichkolwiek śladów użytkowania), wraz z dokumentami potwierdzającymi
          dochowanie 14-sto dniowego terminu od daty zakupu (paragon lub
          fakturę).
        </p>
        <p> Adres do zwrotu:</p>
        <p> Gimnastyczna 92, 94-128,</p>
        <p> Łódź (paczkę należy oznaczyć jako „ZWROT”)</p>
        <p>
          W przypadku odstąpienia od umowy w trybie wskazanym powyżej
          Sprzedający zobowiązany jest niezwłocznie zwrócić środki pieniężne na
          podany przez Klienta rachunek bankowy, nie później niż w terminie 14
          dni.
        </p>
        <p>
          Sprzedający może odmówić przyjęcia przesyłki wysyłanej za pobraniem
          lub na koszt sklepu.
        </p>
        <p>
          Produkty personalizowane, wykonywane wg indywidualnych wytycznych
          Klienta, nie podlegają zwrotom.
        </p>
        <h2>VII. POSTANOWIENIA KOŃCOWE</h2>
        <p>
          Wszystkie informacje, zdjęcia produktów, znaki firmowe producentów są
          chronione prawami autorskimi. Zabrania się ich kopiowania i
          rozpowszechniania, używania do celów handlowych lub prezentacji bez
          zgody właściciela. Wszelkie spory wynikłe na tle Regulaminu strony
          będą rozwiązywały polubownie, zaś w razie braku polubownego
          rozwiązania, organem właściwym do rozwiązania sporu będzie sąd
          właściwy według miejsca zamieszkania lub siedziby pozwanego. W
          sprawach nieuregulowanych niniejszym Regulaminem zastosowanie mają
          przepisy ustawy z dnia 2 marca 2000 r. o ochronie niektórych praw
          konsumentów oraz o odpowiedzialności za szkodę wyrządzoną przez
          produkt niebezpieczny (Dz.U. 2000 nr 22 poz. 271 ze zm.), ustawy z
          dnia 27 lipca 2002 r. o szczególnych warunkach sprzedaży konsumenckiej
          oraz o zmianie Kodeksu cywilnego (Dz.U. 2002 nr 141 poz. 1176 ze zm.),
          ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny (Dz.U. 1964 nr 16
          poz. 93 ze zm.). Spory wynikające ze stosowania niniejszego Regulaminu
          i w związku z wykonywaniem zawartych umów między Sklepem a Klientami
          będą rozpatrywane przez Sąd właściwy według przepisów o właściwości
          rzeczowej i miejscowej zgodnie z ustawą z dnia 17.11.1964 r. Kodeks
          postępowania cywilnego (Dz.U. nr 43 poz. 296 ze. zm.). Złożenie
          zamówienia oznacza akceptację postanowień niniejszego Regulaminu.
        </p>
        <h2>VIII. POLITYKA PRYWATNOŚCI</h2>
        <h3> §.1 Postanowienia Ogólne</h3>
        <p>
          Administratorem danych jest Autorska Pracownia Architektury Grażyna
          Zuterek z siedzibą w Łodzi, ul. Gimnastyczna 92 NIP: 7281124328, REGON
          470034970. Ochrona danych odbywa się zgodnie z wymogami powszechnie
          obowiązujących przepisów prawa, a ich przechowywanie ma miejsce na
          zabezpieczonych serwerach.
        </p>
        <p>
          Termin „RODO” oznacza Rozporządzenie Parlamentu Europejskiego i Rady
          (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
          fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
          swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
        </p>
        <p>
          Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym
          celu używany jest m.in. bezpieczny protokół szyfrowania komunikacji
          (SSL).
        </p>
        <p>
          Dane osobowe podawane w formularzu są traktowane jako poufne i nie są
          widoczne dla osób nieuprawnionych.
        </p>
        <h3>§.2 Administrator danych </h3>
        <p>
          Usługodawca jest administratorem danych swoich klientów. Oznacza to,
          że jeśli posiadasz Konto na naszej stronie, to przetwarzamy Twoje dane
          jak: imię, nazwisko, adres e-mail, numer telefonu, stanowisko, miejsce
          pracy, adres IP.
        </p>
        <p>
          Dane osobowe przetwarzane są:
          <ul>
            <li>
              a. zgodnie z przepisami dotyczącymi ochrony danych osobowych,
            </li>
            <li> b. zgodnie z wdrożoną Polityką Prywatności,</li>
            <li>
              c. w zakresie i celu niezbędnym do nawiązania, ukształtowania
              treści Umowy, zmiany bądź jej rozwiązania oraz prawidłowej
              realizacji Usług świadczonych drogą elektroniczną,
            </li>
            <li>
              d. w zakresie i celu niezbędnym do wypełnienia uzasadnionych
              interesów (prawnie usprawiedliwionych celów), a przetwarzanie nie
              narusza praw i wolności osoby
            </li>
          </ul>
        </p>
        <p>
          Każda osoba, której dane dotyczą ma prawo dostępu do danych,
          sprostowania, usunięcia lub ograniczenia przetwarzania, prawo
          sprzeciwu, prawo wniesienia skargi do organu nadzorczego.
        </p>
        <p>
          Kontakt z osobą nadzorującą przetwarzanie danych osobowych w
          organizacji Usługodawcy jest możliwy drogą elektroniczną pod adresem
          e-mail: zutizuterful@gmail.com
        </p>
        <p>
          Zastrzegamy sobie prawo do przetwarzania Twoich danych po rozwiązaniu
          Umowy lub cofnięciu zgody tylko w zakresie na potrzeby dochodzenia
          ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe albo
          unijne bądź prawa międzynarodowego obligują nas do retencji danych.
        </p>
        <p>
          Usługodawca ma prawo udostępniać dane osobowe Użytkownika oraz innych
          jego danych podmiotom upoważnionym na podstawie właściwych przepisów
          prawa (np. organom ścigania).
        </p>
        <p>
          Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody
          bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie
          danych osobowych.
        </p>
        <p>
          Usługodawca nie udostępniania danych osobowych innym podmiotom aniżeli
          upoważnionym na podstawie właściwych przepisów prawa.
        </p>
        <p>
          Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas albo
          przetwarzający, z którymi ściśle współpracujemy.
        </p>
        <h3>§3. Pliki cookies</h3>
        <p>
          Witryna używa cookies. Są to niewielkie pliki tekstowe wysyłane przez
          serwer www i przechowywane przez oprogramowanie komputera
          przeglądarki. Kiedy przeglądarka ponownie połączy się ze stroną,
          witryna rozpoznaje rodzaj urządzenia, z którego łączy się użytkownik.
        </p>
        <p>
          Parametry pozwalają na odczytanie informacji w nich zawartych jedynie
          serwerowi, który je utworzył. Cookies ułatwiają więc korzystanie z
          wcześniej odwiedzonych witryn.
        </p>
        <p>
          Gromadzone informacje dotyczą adresu IP, typu wykorzystywanej
          przeglądarki, języka, rodzaju systemu operacyjnego, dostawcy usług
          internetowych, informacji o czasie i dacie, lokalizacji oraz
          informacji przesyłanych do witryny za pośrednictwem formularza
          kontaktowego.
        </p>
        <p>Na naszej witrynie wykorzystujemy następujące pliki cookies.</p>
        <ul>
          <li>
            a) „niezbędne” pliki cookies, umożliwiające korzystanie z usług
            dostępnych w ramach serwisu, np. uwierzytelniające pliki cookies
            wykorzystywane do usług wymagających uwierzytelniania w ramach
            serwisu;
          </li>
          <li>
            b) pliki cookies służące do zapewnienia bezpieczeństwa, np.
            wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w
            ramach serwisu;
          </li>
          <li>
            c) „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji
            o sposobie korzystania ze stron internetowych serwisu;
          </li>
          <li>
            d) „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie”
            wybranych przez użytkownika ustawień i personalizację interfejsu
            użytkownika, np. w zakresie wybranego języka lub regionu, z którego
            pochodzi użytkownik, rozmiaru czcionki, wyglądu strony internetowej
            itp.;
          </li>
          <li>
            e) „reklamowe” pliki cookies, umożliwiające dostarczanie
            użytkownikom treści reklamowych bardziej dostosowanych do ich
            zainteresowań.
          </li>
        </ul>
        <p>
          Użytkownik w każdej chwili ma możliwość wyłączenia lub przywrócenia
          opcji gromadzenia cookies poprzez zmianę ustawień w przeglądarce
          internetowej.
        </p>
      </div>
    </Layout>
  )
}

export default AboutPage
